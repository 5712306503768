const timeQuestions = [
  {
    question: 'What’s the expected time commitment for the study?',
    answer:
      'In addition to wearing the wearable and charging it regularly, the other requirement of the study is the weekly surveys.  The length of the surveys will vary but we anticipate that it should take no more than 15 minutes every week.',
  },
  {
    question: 'What’s the duration of the study?',
    answer: 'This study is for the entire school year.',
  },
  {
    question:
      'I’m worried about balancing participation in this study with academics and extracurriculars during the school year. Can I withdrawal from the study?',
    answer:
      'Yes, you can withdraw from the study at any time for any reason without any penalty. Please contact the WearDuke coordinator by email at wearduke@duke.edu',
  },
];

export default timeQuestions;
