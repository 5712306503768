import React from 'react';
import styled from 'styled-components';

import palette from '../shared/palette';

const Question = props => {
  return (
    <Container>
      <QuestionText>{props.question}</QuestionText>
      <AnswerText>{props.answer}</AnswerText>
    </Container>
  );
};

export default Question;

const Container = styled.div``;

const QuestionText = styled.p`
  color: ${palette.dark1};
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const AnswerText = styled.p`
  color: ${palette.dark2};
  font-size: 2rem;
`;
