const registrationQuestions = [
  {
    question: 'I’m really interested! How do I join?',
    answer:
      'Great! If you’re in Gilbert Addoms next year, then you’re all set! Please look forward to a letter in the mail and/or email over the summer along with instructions on how to enroll.',
  },
  {
    question: 'I’m not in Gilbert Addoms this year. Can I still join?',
    answer:
      'Unfortunately, this study is in the first year of the pilot series and only open to residents of Gilbert Addoms.',
  },
  {
    question: 'I’m under 18 years old. Can I still join?',
    answer:
      'Yes! If you’re under 18 when enrollment opens, we’ll just need to get permission from your parent/guardian first before you can enroll. When you turn 18, we will need to get your permission again.',
  },
];

export default registrationQuestions;
