const wearablesQuestions = [
  {
    question: 'If I successfully complete the study, can I keep the wearable?',
    answer:
      'Yes, if you remain an active participant by completing at least half of the surveys and wearing the wearable regularly for the fall semester, the wearable is all yours!',
  },
  {
    question:
      'What are the available options for wearables? Do I get to choose?',
    answer:
      "Depending on your phone, you will be able to choose between an Apple Watch 3 (38mm) or a Fitbit Charge (standard band/colors). If you don't have a smartphone, please contact us.",
  },
  {
    question: 'I already have a wearable. Can I still participate?',
    answer:
      'Yes, you can still participate in the study if you have one of the wearables (must be the same version/model).  At the end of the study, you can receive a wearable for compensation or donate it for next year’s study.',
  },
];

export default wearablesQuestions;
