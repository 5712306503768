import React from 'react';
import styled from 'styled-components';

import Page from '../shared/Page';
import palette from '../shared/palette';
import Section from './Section';

import contactQuestions from './questions/contact';
import privacyQuestions from './questions/privacy';
import registrationQuestions from './questions/registration';
import timeQuestions from './questions/time';
import wearablesQuestions from './questions/wearables';

const FAQPage = () => {
  return (
    <FaqPage>
      <Section title="Registration" questions={registrationQuestions} />
      <Section title="Wearables" questions={wearablesQuestions} />
      <Section title="Time" questions={timeQuestions} />
      <Section title="Privacy" questions={privacyQuestions} />
      <Section title="Contact" questions={contactQuestions} />
    </FaqPage>
  );
};

export default FAQPage;

const FaqPage = styled(Page)`
  background-color: ${palette.dark1};
`;
