import React from 'react';
import palette from '../../shared/palette';

const contactQuestion = [
  {
    question:
      'I have further questions. Who can I contact for more information?',
    answer: [
      'We’re happy to help answer any additional questions or provide clarifications on detail. Please email us at ',
      <a
        style={{
          color: palette.dark2,
          textDecoration: 'none',
          borderBottom: `1px solid ${palette.dark2}`,
        }}
        href="mailto:wearduke@duke.edu"
      >
        wearduke@duke.edu.
      </a>,
    ],
  },
];

export default contactQuestion;
