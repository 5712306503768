import React from 'react';
import styled from 'styled-components';

import palette from '../shared/palette';
import Question from './Question';

const Section = props => {
  const questions = props.questions.map(question => {
    return <Question question={question.question} answer={question.answer} />;
  });

  return (
    <Container>
      <Title>{props.title}</Title>
      <QuestionsContainer>{questions}</QuestionsContainer>
    </Container>
  );
};

export default Section;

const Container = styled.div`
  background-color: ${palette.light1};
`;

const Title = styled.h1`
  margin: 0 auto;
  font-size: 4rem;
  font-weight: 200;
  text-align: left;
  letter-spacing: 2px;
  color: ${palette.dark1};
  font-family: 'Montserrat', sans-serif;
  background-color: ${palette.dark1};
  color: ${palette.light1};
  padding: 2rem;
  padding-left: 5rem;
  padding-top: 3.5rem;

  @media (max-width: 400px) {
    text-align: center;
    padding: 2rem 1rem;
    padding-top: 3.5rem;
  }
`;

const QuestionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-row-gap: 5rem;
  grid-column-gap: 10rem;
  padding: 7rem 5rem;
  justify-content: center;
  margin: 0 auto;
`;
