const privacyQuestions = [
  {
    question:
      'Wearables are known for their multi-functionality purposes. What kind of data are you collecting?',
    answer:
      'For iOS users, we will only collect information through the Health app, which includes sleep, heart rate, and activity data. We strongly encourage students to familiarize themselves with the device and review the account/privacy settings to allow/disallow permissions with regard to your personal information. For Android users, Fitbit owns the data that wearable users generate with their product; so, these users should review the terms of use for this product. Other smartphone apps may obtain or use information in such a way so that protections we have placed on the data would not be binding on them.',
  },
  {
    question: 'What is the data for?',
    answer:
      'Your data will ultimately be used to help you and fellow students to improve your own performance and better keep track of your own health. We will not analyze the data at the individual level, but instead combine the data of all participants to understand about health behaviors and develop interventions that are responsive to student needs.',
  },
  {
    question: 'Who will see my data?',
    answer:
      'Only study investigators and the study coordinator will have access to the data files. All data will be stored with a study ID number, so that your name will not be associated with your data. All data will be securely stored at Duke.',
  },
  {
    question: 'Will you have access to my personal apps on my watch?',
    answer:
      'No, we will only obtain information through the health application. We will not have access to data from any other app.',
  },
  {
    question:
      'Will my data still remain if I choose to withdraw from the study?',
    answer:
      'In the case that you choose to withdraw from the study, we will cease collecting data when you notify us of your decision. Any data collected up to the day you withdraw will be stored for later analysis.',
  },
];

export default privacyQuestions;
