import React from 'react';

import Layout from '../components/layout';
import FAQPage from '../FAQPage/FAQPage';

export default () => (
  <Layout>
    <FAQPage />
  </Layout>
);
